import { App } from "./app";


function urlBase64ToUint8Array(base64String: string) {
	const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
	const base64 = (base64String + padding).replace(/\-/g, '+').replace(/_/g, '/');

	const rawData = window.atob(base64);
	const outputArray = new Uint8Array(rawData.length);

	for (let i = 0; i < rawData.length; ++i) {
		outputArray[i] = rawData.charCodeAt(i);
	}
	return outputArray;
}

export class Push {
	ready = false;
	currentState = "";
	currentStateMessage = ""
	isPushEnabled = false;
	applicationServerKey = 'BG3vEn19dRUpjfO_p9c5QQnTmLRDZQhL-8ZJijR9guub0BFdYJlfq1dwFxCFN6WYobLEeaNnKVpD__vqI5B6azs';
	static _instance: Push;

	constructor() {
		if (Push._instance) {
			return Push._instance
		}

		Push._instance = this;
	}
	subscribe() {
		if (!this.isPushEnabled) {
			this.push_subscribe();
		}
	}

	private push_subscribe() {
		this.changeState('computing');

		return this.checkNotificationPermission()
			.then(() => {


				return navigator.serviceWorker.ready
			})
			.then(serviceWorkerRegistration => {
				return serviceWorkerRegistration.pushManager.subscribe({
					userVisibleOnly: true,
					applicationServerKey: urlBase64ToUint8Array(this.applicationServerKey),
				})
			})
			.then(subscription => {

				// Subscription was successful
				// create subscription on your server
				return this.push_sendSubscriptionToServer(subscription, 'POST');
			})
			.then(subscription => subscription && this.changeState('enabled')) // update your UI
			.catch(e => {
				if (Notification.permission === 'denied') {
					// The user denied the notification permission which
					// means we failed to subscribe and the user will need
					// to manually change the notification permission to
					// subscribe to push messages
					console.warn('Notifications are denied by the user.');
					this.changeState('incompatible');
				} else {
					// A problem occurred with the subscription; common reasons
					// include network errors or the user skipped the permission
					console.error('Impossible to subscribe to push notifications', e);
					this.changeState('disabled');
				}
			});
	}
	changeState(state: string, button?: HTMLButtonElement) {


		switch (state) {
			case 'enabled':
				this.currentStateMessage = 'Disable Push notifications';
				this.isPushEnabled = true;
				break;
			case 'disabled':
				this.currentStateMessage = 'Enable Push notifications';
				this.isPushEnabled = false;
				break;
			case 'computing':
				this.currentStateMessage = 'Loading...';
				break;
			case 'incompatible':
				this.currentStateMessage = 'Push notifications are not compatible with this browser';
				break;
			default:
				console.error('Unhandled push button state', state);
				break;
		}
	}
	push_updateSubscription() {
		navigator.serviceWorker.ready
			.then(serviceWorkerRegistration => serviceWorkerRegistration.pushManager.getSubscription())
			.then(subscription => {
				this.changeState('disabled');

				if (!subscription) {
					// We aren't subscribed to push, so set UI to allow the user to enable push
					return;
				}

				// Keep your server in sync with the latest endpoint
				return this.push_sendSubscriptionToServer(subscription, 'POST');
			})
			.then(subscription => subscription && this.changeState('enabled')) // Set your UI to show they have subscribed for push messages
			.catch(e => {
				console.error('Error when updating the subscription', e);
			});
	}

	push_unsubscribe() {
		this.changeState('computing');

		// To unsubscribe from push messaging, you need to get the subscription object
		navigator.serviceWorker.ready
			.then(serviceWorkerRegistration => serviceWorkerRegistration.pushManager.getSubscription())
			.then(subscription => {
				// Check that we have a subscription to unsubscribe
				if (!subscription) {
					// No subscription object, so set the state
					// to allow the user to subscribe to push
					this.changeState('disabled');
					return;
				}

				// We have a subscription, unsubscribe
				// Remove push subscription from server
				return this.push_sendSubscriptionToServer(subscription, 'DELETE');
			})
			.then(subscription => subscription?.unsubscribe())
			.then(() => this.changeState('disabled'))
			.catch(e => {
				// We failed to unsubscribe, this can lead to
				// an unusual state, so  it may be best to remove
				// the users data from your data store and
				// inform the user that you have done so
				console.error('Error when unsubscribing the user', e);
				this.changeState('disabled');
			});
	}

	private push_sendSubscriptionToServer(subscription: PushSubscription, method: string = "POST") {


		const key = subscription.getKey('p256dh');
		const authToken = subscription.getKey('auth');
		const contentEncoding = (PushManager.supportedContentEncodings || ['aesgcm'])[0];

		return fetch('webapp/update_push_token', {
			method,
			body: JSON.stringify({
				endpoint: subscription.endpoint,
				key: key ? window.btoa(String.fromCharCode.apply(null, new Uint8Array(key) as unknown as number[])) : null,
				authToken: authToken ? window.btoa(String.fromCharCode.apply(null, new Uint8Array(authToken) as unknown as number[])) : null,
				contentEncoding,
			}),
		}).then(() => subscription);
	}

	checkNotificationPermission() {
		return new Promise<void>((resolve, reject) => {

			if (Notification.permission === 'denied') {
				return reject(new Error('Push messages are blocked.'));
			}

			if (Notification.permission === 'granted') {
				return resolve();
			}

			return Notification.requestPermission().then(result => {
				if (result !== 'granted') {
					reject(new Error('Bad permission result'));
				} else {
					resolve();
				}
			});
		});
	}
	init() {

		if (!('serviceWorker' in navigator)) {
			console.warn('Service workers are not supported by this browser');
			this.currentState = "incompatible";
			this.currentStateMessage = "Dein Browser unterstützt keine Push-Benachrichtigungen";
			this.changeState('incompatible');
			return;
		}

		if (!('PushManager' in window)) {
			console.warn('Push notifications are not supported by this browser');
			this.changeState('incompatible');
			return;
		}

		if (!('showNotification' in ServiceWorkerRegistration.prototype)) {
			console.warn('Notifications are not supported by this browser');
			this.changeState('incompatible');
			return;
		}

		// Check the current Notification permission.
		// If its denied, the button should appears as such, until the user changes the permission manually
		if (Notification.permission === 'denied') {
			console.warn('Notifications are denied by the user');
			this.changeState('incompatible');
			return;
		}
	}


}
