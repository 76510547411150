import { App, AppConfig, Page } from '../_/TS/app';
import { Dialog } from '../_/TS/modal';
import { Push } from '../_/TS/push';
import { Sub as Sub3} from './page/sub';
import { ContentPage } from "tp_content/src/page/content";


import 'bootstrap';
import { ContentFilePage } from 'tp_content/src/page/content_file';
import { _ } from '../_/TS/mini';



declare global {
	var config: AppConfig;		
}

(() => {

	const config: AppConfig = {
		base: _("body").data("base") as string,
		pageMap : {
			"content/template": ContentPage,
			"content/index": ContentPage,
			"content/file": ContentFilePage,
		}
	
	}
	console.log("config", config);
	const app = new App(config);

	app.init();
	
	navigator.serviceWorker.ready.then((serviceWorkerRegistration) => {
		const push = new Push();
		push.init();
	
		serviceWorkerRegistration.active?.postMessage({ type: 'getPushMessage' })
	})
	
	navigator.serviceWorker.addEventListener('message', function (event) {
		if (event.data && event.data.type === 'SHOW_MODAL') {
			new Dialog({
				title: event.data.payload.headline,
				body: event.data.payload.subHeadline,
			}).show();
		}
	})
	
	
})()


