

import { Page } from "../../../../_/TS/app";



export class ContentPage extends Page {
    content?: any
    init(): void {
        this.content = import("tp_content").then(
            Content => {
                return Content.init(this.html);
            }
        )
    }

    async onEnter() {
        this.content.then((content: any) => {
            setTimeout(() => {
                content?.initHtmlEditor();
            }, 100);

        })
    }

    onLeave() {
        this.content.then((content: any) => {
            content?.destroy();
        })
		return true;
    }
}