

import { Page } from "../../../../_/TS/app";
import { _, __ } from "../../../../_/TS/mini";
import Fupload, { FileInfo } from "../fupload";



export class ContentFilePage extends Page {
	init(): void {

		console.log("init content file page")
		__("input", this.html).each(function (index, img) {
			new Fupload(img as HTMLInputElement,
				{
					aspectRatio: 300 / 167,
					url: `${config.base}filemanager/upload`,
					cropperImageWidth: 600,

				},
				(e, res: FileInfo[]) => {
					let path = res[0].path;

					_app.reloadPage();
					
				},
				true,
			);
		});





	}

	async onEnter() {

	}

	onLeave() {
		return true;
	}
}